import { TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { Line, LineChart, XAxis, YAxis } from 'recharts';

type PriceData = {
  price: number,
  time: Date
}

const API_KEY = '5CYBLWM0FOHWNIW1';

interface Price {
  [key: string] : string;
}

export const MyChart = () => {
  const stock1 = useRef<string>('');
  const stock2 = useRef<string>('');

  const [prices1, setPrices1] = useState<PriceData[]>([]);
  const [prices2, setPrices2] = useState<PriceData[]>([]);

  const getStockPrice = async (stock: string) => {
    console.log("making request for stock", stock);
    const res = await fetch(`https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${stock}&apikey=${API_KEY}`);
    const json = await res.json();
    return json;
  }

  const jsonToPrice  = (json: any) => {
    const stockprices = json['Monthly Adjusted Time Series'];

    return Object.entries(stockprices).map(e => {
      const date = new Date(e[0]);
      const obj = e[1] as Price;
      const price = obj['4. close'];
      return {price: Number(price), time: date}
    });
  }

  const fetchPrices = async () => {
    console.log("fetching prices", stock1.current);
    try {
      const data1 = await getStockPrice(stock1.current);
      const prices1 = jsonToPrice(data1);

      const data2 = await getStockPrice(stock2.current);
      const prices2 = jsonToPrice(data2);

      setPrices1(prices1);
      setPrices2(prices2);
    } catch(err) {
      alert("error fetching prices");
    }
  }

  return (
    <div className="">
      <div className="p-5">
        <TextField
          InputProps={{
            style: {
                color: "#7683de"
            }
          }}
          size="small" 
          onChange={v => stock1.current = v.target.value} />
      </div>

      <div className="p-5">
        <TextField 
          InputProps={{
            style: {
                color: "#cc0b04"
            }
          }}
          size="small"
          onChange={v => stock2.current = v.target.value} />      
      </div>

      <button onClick={() => fetchPrices()}>compare</button>

      {(prices1.length > 0 || prices2.length > 0) &&
        <div className='justify-center items-center'>
          <LineChart width={1000} height={300} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line dot={false} data={prices1} type="monotone" dataKey="price" stroke="#7683de" />
            <Line dot={false} data={prices2} type="monotone" dataKey="price" stroke="#cc0b04" />
            <XAxis tick={false} dataKey="time" />
            <YAxis />
          </LineChart>
        </div>

      }

    </div>

  )
}
